import { atomWithStorage } from "jotai/utils";
import { Person } from "./types";

interface BusinessToPartner extends Person {
  partnerCode?: string;
}

interface FreshPartner {
    partnerMethod: (string | number)[];
    partnerType: string;
    PartnerCode: string;
    email: string;
    token: string;
  firstName: string;
  lastName: string;
  phone: string;
  workDepartment: string;
  promotionMethod: string;
    partnerIdentity: (string | number)[];
    partnerIdInfo: string;
  website: string;
  identity: string;
  customerBaseSize: string | number;
  customerBaseDescription: string;
    id: string | number;
    acceptedPartner: boolean;
    flowStatus: string;
}

export const partnerDetailsAtom = atomWithStorage<BusinessToPartner>(
  "partnerDetails",
  {} as BusinessToPartner
);

export const freshPartnerAtom = atomWithStorage(
  "freshPartner",
  {} as FreshPartner
);
