import {
    Flex,
    Heading,
    Icon,
    LightMode,
    useColorMode,
    VStack,
} from "@chakra-ui/react";
import { RESET } from "jotai/utils";
import { faCaretDown } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { motion } from "framer-motion";
import type { NextPage } from "next";
import { useEffect } from "react";
import EconicNavBar from "../components/EconicNavBar";
import DemoSection from "../components/home/DemoSection";
import Footer from "../components/home/Footer";
import InteractiveEconicSection from "../components/home/InteractiveEconicSection";
import { freshPartnerAtom } from "../atoms";
import { atom, useAtom } from "jotai";
import { HTMLAttributes } from "react";

export function getServerSideProps({ req }: any) {
    return {
        props: {
            // first time users will not have any cookies and you may not return
            // undefined here, hence ?? is necessary
            cookies: req.headers.cookie ?? "",
        },
    };
}

const Home: NextPage = () => {
    const { colorMode, toggleColorMode } = useColorMode();
    const [freshPartnerData, setFreshPartnerData] = useAtom(freshPartnerAtom);
    //  useEffect(() => {
    //      //added in a reset for the partner information
    //      setFreshPartnerData(RESET);
    //      colorMode == "dark" ? toggleColorMode() : null;
    //}, [colorMode]);
    return (
        <Flex minW="100vw" minH="100vh">
            <iframe src="https://econicnetwork.com/"
                width="100%"
                height="100%"
                style={{ position: 'absolute', height: '100%' }}
                seamless={true}>
            </iframe>
        </Flex>
    );
};

export default Home;

const TopSection = () => {
    const { colorMode, toggleColorMode } = useColorMode();
    return (
        <Flex
            minH={"75vh"}
            w="full"
            justify="center"
            align="center"
            bg={colorMode == "dark" ? "brand.1000" : "black"}
            textAlign="center"
            position="relative"
            color="white"
        >
            <Flex direction="column" maxW={["85%", null, "90%"]} gap={[5, null, 5]}>
                <Heading
                    size={["2xl", null, "2xl", "3xl"]}
                    as={motion.div}
                    initial={{ y: 200, opacity: 0 }}
                    whileInView={{
                        y: 0,
                        opacity: 1,
                        transition: {
                            duration: 0.5,
                            ease: "easeOut",
                        },
                    }}
                >
                    Small businesses now have big business tools.{" "}
                </Heading>
                <Heading
                    size={["lg", "lg", "xl", "2xl"]}
                    fontWeight="normal"
                    as={motion.div}
                    initial={{ y: 200, opacity: 0 }}
                    whileInView={{
                        y: 0,
                        opacity: 1,
                        transition: {
                            duration: 0.75,
                            ease: "easeOut",
                            delay: 0.25,
                        },
                    }}
                > Cash flow causes 80% of all small business closures. "The 100%" reading that needs a solution.
                </Heading>
            </Flex>
            <VStack
                pos="absolute"
                bottom={5}
                as={motion.div}
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1, transition: { duration: 0.5, delay: 1.25 } }}
                left="auto"
                right="auto"
            >
                <Heading
                    size={["md", null, "lg"]}
                    as={motion.div}
                    fontWeight="hairline"
                >
                    Scroll to learn more
                </Heading>
                <Icon color="brand.orange">
                    <FontAwesomeIcon icon={faCaretDown} fade />
                </Icon>
            </VStack>
        </Flex>
    );
};